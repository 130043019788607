<template>
  <div class="">
    <div class="step-text">
      <p class="step-text-title">{{ steps.text.text0 }}</p>
      <p class="step-text-sub">{{ steps.text.text1 }}</p>
      <p class="step-text-sub">{{ steps.text.text2 }}</p>
    </div>

    <div class="step-list-container">
      <div
        v-for="(item, index) in steps.list"
        :key="index"
        class="step-list"
      >
        <img :src="item.src" alt="">
        <div>
          <span class="step-item-index">step {{ index + 1 }}</span>
          <p class="step-item-title">{{ item.title }}</p>
          <p class="step-item-sub">{{ item.desc }}</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  components: {},
  props: {
    steps: { type: Object, default: () => ({ text: {}, list: [] }) }
  },
  data() {
    return {
    }
  }
}
</script>
<style lang="scss" scoped>
.step-text {
  text-align: center;
  margin-top: 20px;
  font-size: 12px;
  .step-text-title {
    font-size: 14px;
    font-family: 'Montserrat-Bold';
  }
  .step-text-sub {
    font-family: 'Montserrat';
  }
}
.step-list-container {
  img {
    width: 98px;
    height: 70px;
    margin-right: 10px;
  }
  .step-list {
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin: 20px 20px 0 20px;
    border-radius: 20px;
    border: 1px solid #EFEDED;
    .step-item-index {
      color: #fff;
      background: #6D60E1;
      padding: 5px;
      border-radius: 10px;
      display: inline-block;
    }
    .step-item-title {
      font-size: 12px;
      line-height: 28px;
      font-family: 'Montserrat-Bold';
    }
    .step-item-sub {
      font-family: 'Montserrat';
    }
  }
}
</style>
