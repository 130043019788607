<template>
  <div class="main-wrapper">
    <div class="claim-wrapper">
      <FuNav
        :fixed="false"
        :title="$t('ebClaim.Guidance')"
        :arrow="true"
        percent
      />
      <div class="guidance-container">
        <Tabs v-model="active">
          <Tab :title="$t('ebClaim.SubmitClaim')">
            <List :steps="submit" />
          </Tab>
          <Tab :title="$t('ebClaim.TrackingClaim')">
            <List :steps="tracking" />
          </Tab>
        </Tabs>

        <div class="btn-group">
          <div class="btn-group-wapper">
            <Button
              class="fu-v2-btn block"
              round
              block
              type="danger"
              @click="goback"
            >{{ $t('ebClaim.Back') }}</Button>
            <span />
            <Button
              class="fu-v2-btn danger block"
              round
              block
              type="danger"
              @click="jumpTo"
            >{{ active ? $t('ebClaim.TrackingClaim') : $t('ebClaim.SubmitClaim') }}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import FuNav from '@/components/v2/FuNav'
import { Tab, Tabs, Button } from 'vant'
import List from './list.vue'
import Submit0 from '@/assets/imgs/submit0.png'
import Submit1 from '@/assets/imgs/submit1.png'
import Submit2 from '@/assets/imgs/submit2.png'
import Submit3 from '@/assets/imgs/submit3.png'
import Tracking0 from '@/assets/imgs/tracking0.png'
import Tracking1 from '@/assets/imgs/tracking1.png'
import Tracking2 from '@/assets/imgs/tracking2.png'
export default {
  components: { Tab, Tabs, List, Button, FuNav },
  props: {},
  data() {
    return {
      active: 0,
      submit: {
        text: {},
        list: []
      },
      tracking: {
        text: {},
        list: []
      }
    }
  },
  activated() {
    this.submit = {
      text: {
        text0: this.$t('ebClaim.SubmitClaimListQuestion'),
        text1: this.$t('ebClaim.SubmitClaimListP1'),
        text2: this.$t('ebClaim.SubmitClaimListP2')
      },
      list: [
        { src: Submit0, title: this.$t('ebClaim.submitTitle0'), desc: this.$t('ebClaim.submitDesc0') },
        { src: Submit1, title: this.$t('ebClaim.submitTitle1'), desc: this.$t('ebClaim.submitDesc1') },
        { src: Submit2, title: this.$t('ebClaim.submitTitle2'), desc: this.$t('ebClaim.submitDesc2') },
        { src: Submit3, title: this.$t('ebClaim.submitTitle3'), desc: this.$t('ebClaim.submitDesc3') }
      ]
    }
    this.tracking = {
      text: {
        text0: this.$t('ebClaim.TrackingClaimListQuestion'),
        text1: this.$t('ebClaim.TrackingClaimListP1'),
        text2: this.$t('ebClaim.TrackingClaimListP2')
      },
      list: [
        { src: Tracking0, title: this.$t('ebClaim.trackingTitle0'), desc: this.$t('ebClaim.trackingDesc0') },
        { src: Tracking1, title: this.$t('ebClaim.trackingTitle1'), desc: this.$t('ebClaim.trackingDesc1') },
        { src: Tracking2, title: this.$t('ebClaim.trackingTitle2'), desc: this.$t('ebClaim.trackingDesc2') }
      ]
    }
  },
  methods: {
    goback() {
      history.go(-1)
    },
    jumpTo() {
      if (this.active) {
        this.$router.push({ name: 'EbTrackClaim' })
      } else {
        this.$router.push({ name: 'EbSubmitClaim' })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.main-wrapper {
  height: 100vh;
  width: 100vw;
  background: #f5f5f5;
}
@media (min-width: 600px) {
  .claim-wrapper {
    width: 600px;
  }
}
.claim-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  height: 100vh;
  position: relative;
}
.guidance-container {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
}
::v-deep .van-tabs__line {
  width: 50%;
}
// .btn-group {
//   margin: 15px;
// }
.jump-btn {
  margin: 20px;
}
.btn-group {
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  .btn-group-wapper {
    display: flex;
    gap: 10px;
  }
}
</style>
